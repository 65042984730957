.am-icon {
  fill: currentColor;
  background-size: cover;
  width: 0.293333rem;
  height: 0.293333rem;
}

.am-icon-xxs {
  width: 0.2rem;
  height: 0.2rem;
}

.am-icon-xs {
  width: 0.24rem;
  height: 0.24rem;
}

.am-icon-sm {
  width: 0.28rem;
  height: 0.28rem;
}

.am-icon-md {
  width: 0.293333rem;
  height: 0.293333rem;
}

.am-icon-lg {
  width: 0.48rem;
  height: 0.48rem;
}

.am-icon-loading {
  -webkit-animation: cirle-anim 1s linear infinite;
  animation: cirle-anim 1s linear infinite;
}

@-webkit-keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}